import { DELETE_PERMISSION, MAINTAIN_PERMISSION } from "@kraftheinz/common";
import { P as PROFIT_BASE_SALES_FORECAST_BASE, a as PROFIT_EXISTING_BASE_SALESFORECAST } from "./maintain-versions.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Edit", "title": "Edit Version", "after-close": _vm.afterModalClose }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _vm.can(_vm.permissions.delete) ? [_c("a-button", { attrs: { "ghost": "", "type": "danger" }, on: { "click": _vm.onDeleteClick } }, [_vm._v("Delete")])] : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v(" Save ")]) : _vm._e()];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "all-in-value": "", "form-item": "", "label": "Version", "placeholder": "Select Version", "reference": "web-analytics.common.profit-version", "rules": "required", "source": "versionId", "source-label": "version", "value": _vm.version.versionId }, on: { "change": _vm.onVersionChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Fin Year (Cal)", "placeholder": "Select Year", "data-source": _vm.finYears, "rules": "required", "source": "finYear", "value": _vm.finYear }, on: { "change": _vm.onFinYearChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Base", "placeholder": "Select Base", "reference": "web-analytics.common.profit-base", "rules": "required", "source": "base", "value": _vm.base }, on: { "change": _vm.onBaseChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Comment", "max-length": 40, "placeholder": "Input Comment", "rules": "required", "value": _vm.version.comment }, on: { "change": function($event) {
      return _vm.storeValue("comment", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "data-source": _vm.forecastCycles, "form-item": "", "label": "Forecasting Cycle", "placeholder": "Select Forecasting Cycle", "source": "forecastCycle", "value": _vm.version.forecastCycle }, on: { "change": function($event) {
      return _vm.storeValue("forecastCycle", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "data-source": _vm.forecastingTypes, "form-item": "", "label": "Forecasting Process/Type", "placeholder": "Select Forecasting Process/Type", "source": "forecastingType", "value": _vm.version.forecastingType }, on: { "change": function($event) {
      return _vm.storeValue("forecastingType", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Publish Flag", "placeholder": "Select Publish Flag", "reference": "web-analytics.common.profit-publish-flag", "source": "publishFlag", "value": _vm.version.publishFlag }, on: { "change": function($event) {
      return _vm.storeValue("publishFlag", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Base Lock", "placeholder": "Select Base Lock", "reference": "web-analytics.common.profit-base-lock", "source": "baseLock", "value": _vm.version.baseLock }, on: { "change": function($event) {
      return _vm.storeValue("baseLock", $event);
    } } })], 1), _vm.existingBaseAliasVisible ? _c("a-col", { attrs: { "span": 24 } }, [_c("a-row", { staticClass: "select-alias-container", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [!_vm.existingBaseAliasDisabled ? _c("select-input", { attrs: { "data-source": _vm.existingBaseAliases, "form-item": "", "label": "Existing Base Alias", "placeholder": "Select Existing Base Alias", "source": "existingBase", "value": _vm.existingBaseAlias }, on: { "change": _vm.onExistingBaseAliasChange } }) : _c("text-input", { attrs: { "disabled": "", "form-item": "", "label": "Existing Base Alias", "value": _vm.existingBaseAlias } })], 1)], 1)], 1) : _vm._e()], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
var EditMaintainVersions_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".select-alias-container[data-v-5d771c3e]{background-color:#f6f6fa}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const existingBaseApiUrl = `${apiUrl$1}/web-analytics/common/profit-existingbase`;
const forecastCycleApiUrl = `${apiUrl$1}/web-analytics/common/profit-forecast-cycle`;
const forecastingTypeApiUrl = `${apiUrl$1}/web-analytics/common/profit-forecasting-type`;
const finYearApiUrl = `${apiUrl$1}/web-analytics/common/profit-fin-year`;
const __vue2_script$1 = {
  name: "EditMaintainVersions",
  inject: ["can", "crud", "redirectRoute"],
  data() {
    return {
      base: void 0,
      existingBaseAliasVisible: false,
      existingBaseAlias: void 0,
      existingBaseAliasDisabled: false,
      existingBaseAliases: [],
      finYear: void 0,
      finYears: [],
      forecastCycles: [],
      forecastingTypes: [],
      isLoading: false,
      permissions: {
        delete: DELETE_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      },
      visible: false
    };
  },
  computed: {
    version() {
      const version = this.crud.getEntity();
      if (!version)
        return {};
      return {
        ...version,
        base: version.base ? version.base.trim() : version.base,
        existingBase: version.existingBase ? version.existingBase.trim() : version.existingBase
      };
    }
  },
  async created() {
    if (this._.isEmpty(this.version)) {
      this.$router.push(this.redirectRoute);
      return;
    }
    this.storeValues();
    const {
      version,
      existingBase,
      forecastCycle,
      forecastingType,
      iterationId
    } = this.version;
    const [
      existingBaseAliases,
      forecastCycles,
      forecastingTypes,
      finYears
    ] = await Promise.all([
      this.fetchOptions(existingBaseApiUrl, { existingBase }),
      this.fetchOptions(forecastCycleApiUrl, { forecastCycle }),
      this.fetchOptions(forecastingTypeApiUrl + `?iterationId=${iterationId}`, {
        forecastingType
      }),
      this.fetchOptions(finYearApiUrl + `?version=${version}`)
    ]);
    this.finYears = finYears;
    this.existingBaseAliases = existingBaseAliases;
    this.forecastCycles = forecastCycles;
    this.forecastingTypes = forecastingTypes;
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    async fetchOptions(apiUrl2, itemToAppend) {
      try {
        const res = await this.axios.get(apiUrl2);
        if (!itemToAppend || res.data.some((item) => this._.isEqual(item, itemToAppend))) {
          return res.data;
        }
        return [itemToAppend, ...res.data];
      } catch (error) {
        this.$notification.error({ message: error.message });
      }
    },
    storeValue(resourceKey, value) {
      this.crud.updateEntity("editForm", { resourceKey, value });
    },
    storeValues() {
      this.storeValue("version", {
        versionId: this.version.versionId,
        version: this.version.version
      });
      Object.entries(this.version).forEach(([key, value]) => {
        if (key == "version" || key == "versionId") {
          return;
        }
        this.storeValue(key, value);
      });
      this.base = this.version.base;
      this.existingBaseAlias = this.version.existingBase;
      this.finYear = this.version.finYear;
      if (this.version.base) {
        this.existingBaseAliasVisible = true;
        if (this.version.base == PROFIT_BASE_SALES_FORECAST_BASE) {
          this.existingBaseAliasDisabled = true;
        }
      }
    },
    async submit() {
      this.isLoading = true;
      try {
        await this.crud.submitEntity("replace");
        this.crud.fetchList();
        this.isLoading = false;
        this.visible = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    },
    onBaseChange(value) {
      this.base = value;
      this.storeValue("base", value);
      if (!value) {
        return;
      }
      this.existingBaseAliasVisible = true;
      if (value == PROFIT_BASE_SALES_FORECAST_BASE) {
        if (!this.finYear) {
          return;
        }
        this.existingBaseAlias = this.finYear.toString().slice(-2) + PROFIT_EXISTING_BASE_SALESFORECAST;
        this.storeValue("existingBase", this.existingBaseAlias);
        this.existingBaseAliasDisabled = true;
      } else {
        this.existingBaseAlias = void 0;
        this.storeValue("existingBase", void 0);
        this.existingBaseAliasDisabled = false;
      }
    },
    onCancel() {
      this.visible = false;
    },
    onDeleteClick() {
      this.$confirm({
        class: "list__modal-delete",
        icon: "warning",
        title: "Do you want to Delete this version/iteration?",
        content: "If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want to proceed?",
        cancelText: "Cancel",
        okText: "Delete",
        okType: "danger",
        onOk: this.onDestroyEntity
      });
    },
    onDestroyEntity() {
      return this.crud.destroyEntity(void 0, this.version.iterationId).then(() => {
        this.visible = false;
      }).catch((error) => console.log(error));
    },
    onExistingBaseAliasChange(value) {
      this.existingBaseAlias = value;
      this.storeValue("existingBase", value);
    },
    onFinYearChange(value) {
      this.finYear = value;
      this.storeValue("finYear", value);
      if (!this.base || this.base != PROFIT_BASE_SALES_FORECAST_BASE) {
        return;
      }
      this.existingBaseAlias = value.toString().slice(-2) + PROFIT_EXISTING_BASE_SALESFORECAST;
      this.storeValue("existingBase", this.existingBaseAlias);
    },
    async onVersionChange(value) {
      this.finYears = await this.fetchOptions(finYearApiUrl + `?version=${value.version}`);
      this.storeValue("version", value);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "5d771c3e", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditMaintainVersions = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.profit-version", "resource-id-name": "version", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.profit-fin-year", "resource-id-name": "finYear", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.profit-base", "resource-id-name": "base", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.profit-publish-flag", "resource-id-name": "publishFlag", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.profit-base-lock", "resource-id-name": "baseLock", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.version-controls", "api-url": _vm.apiUrl, "resource-id-name": "iterationId", "redirect-route": "/web-analytics/maintain-versions" } }, [_c("edit-maintain-versions")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditMaintainVersions
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
